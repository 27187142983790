import React, {useState, Fragment, useEffect} from 'react';
import NotesList from '../components/notes/NotesList';
import Navbar from '../components/Navbar';
import axios from 'axios';
import {Link} from "react-router-dom";
import {Flex, Box} from 'reflexbox';

interface INote{
  username:string;
  title:string;
  content:string;
  date:Date;
}

export default function Notes(){
  const [notes, setNotes] = useState<INote[]>([]);

  useEffect(() => {
    axios.get("/notes/", { withCredentials: true }).then(response => {
      if (response.data) {
         const routes = [{}];
         response.data.map(note => {
           routes.push({
             path: "/:id",
           sidebar: () => <Box><Link>{note.title}</Link></Box>,
           main: () => <div>{note.content}</div>
           });
         });
      }
    });
  }, []);


    return <Flex flexDirection="column">
      {notes.map(note => {
        return (
        <Box>
          <Link >{note.title}</Link>
        </Box>
      )})}
    </Flex>
}