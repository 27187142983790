import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinHorizontal = keyframes`
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
`;

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 70px;
    height: 70px;
    /* position: fixed; */
    top: 40vh;
    left: calc(50vw - 35px);
    animation: ${spinHorizontal} 1.7s infinite linear;
  }
`;
const Loader = () => (
  <StyledLoader>
    <img src="../static/images/logo.svg" alt="Loading..." />
  </StyledLoader>
);

export default Loader;
