import React, {useState, useEffect} from "react";
import auth from '../../auth';

export default function Login(props){

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoggingIn(true);

    await auth.login(username, password, () => {props.history.push("/notes")})
    
  }

    return (
      <div>
        <h3>Login</h3>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Username: </label>
            <input
              type="text"
              required
              className="form-control"
              value={username}
              onChange={onChangeUsername}
            />
          </div>
          <div className="form-group">
            <label>Password: </label>
            <input
              type="text"
              required
              className="form-control"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <div className="form-group">
            <input type="submit" value="Login" className="btn btn-primary" disabled={isLoggingIn} />
          </div>
        </form>
      </div>
    );
  }
