import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import EditNote from "./components/notes/EditNote";
import CreateNote from "./components/notes/CreateNote";
import LoginPage from "./pages/login";
import { PrivateRoute } from "./PrivateRoute";
import "antd/dist/antd.css";
import Notes from "./pages/notes";

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="container">
          <Navbar />
          <br />
          <Switch>
            <PrivateRoute path="/" exact component={Notes} />
            <PrivateRoute path="/notes" component={Notes} />
            <PrivateRoute path="/create" component={CreateNote} />
            <PrivateRoute path="/edit/:id" component={EditNote} />
            <Route path="/login" component={LoginPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}
