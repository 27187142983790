import axios from "axios";

class Auth {
  constructor() {}

  login(username, password, cb) {
    axios.post("/auth/token", { username, password }).then(response => {
      cb();
    });
  }

  logout(cb) {
    cb();
  }

  async isAuthenticated() {
    try {
      var response = await axios.get("http://localhost:3000/auth/ping");
      return response.data.success;
    } catch (ex) {
      return false;
    }
  }
}

const singletonInstance = new Auth();
Object.freeze(singletonInstance);

export default singletonInstance;

// .then(response => {
//   console.log("Contacted auth");
//   if (response.data.success) {
//     console.log("Authed");
//     authed = true;
//     console.log("Set authed to true");
//   } else {
//     console.log("Not authed");
//     authed = false;
//     console.log("Set authed to false");
//   }
// })
// .catch(error => {
//   console.log(error);
//   authed = false;
// })
