import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import Loader from './components/lib/Loader';
import auth from './auth';

interface IState {
  loggedIn: boolean;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({component: Component, ...rest}) => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    setCheckingAuth(true);
    auth.isAuthenticated().then(res => {console.log(res); setLoggedIn(res); setCheckingAuth(false)});
  }, []);

    return (
      <Route {...rest} render={props => {

        if(checkingAuth){
          return <Loader/>
        }

        if(loggedIn && !checkingAuth)
        {
          return <Component {...props} />;
        }
        
        if(!loggedIn && !checkingAuth)
        {
          return <Redirect to={{pathname: "/login", state: {from: props.location}}} />
        }
      }

      } />
      
    )
  }
